import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import GlobalContext from "./../GlobalContext";
import CloseButton from "./../icons/CloseButton";
import Search from "./../icons/Search";
import MiscSocialSmall from "./../icons/MiscSocialSmall";
import { SlidingMenuWrapper } from "./elements";

/**
Notes on behavior:

- if an item has subentries, clicking on it shows the subentries. It doesn't return the item's id.
- There's no indicator that some things expand. Should there be? They could turn orange on hover?
- Social buttons need to be separated out.
	
	*/

const buildMenuContent = (menuContent) => {
  let realMenuContent = [];
  for (let i = 0; i < menuContent?.length; i++) {
    let thisMenuContent = menuContent[i].menuItem;
    if (menuContent[i].menuSectionSubitems) {
      for (let j = 0; j < menuContent[i].menuSectionSubitems.length; j++) {
        if (menuContent[i].menuSectionSubitems[j].parentLink) {
          for (let k = 0; k < thisMenuContent.length; k++) {
            if (
              thisMenuContent[k].link ===
              menuContent[i].menuSectionSubitems[j].parentLink
            ) {
              if (thisMenuContent[k].subItems) {
                // check if it's already in there
                const theSubItemLinks = thisMenuContent[k].subItems.map(
                  (x) => x.name
                );

                if (
                  theSubItemLinks.indexOf(
                    menuContent[i].menuSectionSubitems[j].name
                  ) < 0
                ) {
                  thisMenuContent[k].subItems[
                    thisMenuContent[k].subItems.length
                  ] = menuContent[i].menuSectionSubitems[j];
                }
              } else {
                thisMenuContent[k].subItems = [
                  menuContent[i].menuSectionSubitems[j],
                ];
              }
            }
          }
        }
      }
    }
    realMenuContent[i] = thisMenuContent;
  }
  return realMenuContent;
};

const Menu = ({ onCloseMenu, onItemSelected }) => {
  const { menuContent, useStore, useStaging } = React.useContext(GlobalContext);
  const [openSubMenus, setOpenSubMenus] = React.useState([]);
  const realMenuContent = React.useMemo(() => buildMenuContent(menuContent));
  const location = useLocation();
  const myPathname = location.pathname.split("/")[1];

  const realUseStore = useStore || useStaging;

  // console.log(menuContent);
  // console.log(menuShown);
  const fireCloseMenu = () => {
    // console.log("close button clicked!");
    onCloseMenu();
  };

  React.useEffect(() => {
    // console.log("firing useEffect!", myPathname, realMenuContent, openSubMenus);
    if (
      myPathname &&
      realMenuContent &&
      realMenuContent.length &&
      !openSubMenus.length
    ) {
      // console.log(openSubMenus);
      for (let i = 0; i < realMenuContent.length; i++) {
        const thisMenuSection = realMenuContent[i];
        for (let j = 0; j < thisMenuSection.length; j++) {
          if (thisMenuSection[j].subItems) {
            const subItemsLinks = thisMenuSection[j].subItems.map(
              (x) => x.link
            );
            if (subItemsLinks.indexOf(myPathname) > -1) {
              setOpenSubMenus([thisMenuSection[j].link]);
            }
          }
        }
      }
    }
  }, [myPathname, realMenuContent]);

  return (
    <SlidingMenuWrapper>
      <nav>
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            fireCloseMenu();
          }}
          aria-label={"Close menu"}
        >
          <CloseButton />
        </a>
      </nav>
      {realMenuContent && realMenuContent.length ? (
        <div>
          {realMenuContent.map((section, index) => (
            <ul key={index}>
              {typeof section === "object"
                ? section.map((item, index2) =>
                    item.subItems ? (
                      <li key={index2}>
                        <a
                          href={`/${item.link}`}
                          data-submenutype={item.link}
                          className={myPathname === item.link ? "disabled" : ""}
                          disabled={myPathname === item.link}
                          onClick={(e) => {
                            e.preventDefault();
                            const myId =
                              e.target.getAttribute("data-submenutype");
                            if (openSubMenus.indexOf(myId) > -1) {
                              // console.log("open!", myId);
                              // console.log(openSubMenus);
                              setOpenSubMenus(
                                openSubMenus.filter((x) => x !== myId)
                              );
                              if (
                                item.link === "programme" &&
                                typeof window !== "undefined"
                              ) {
                                localStorage.removeItem("theme");
                              }
                              navigate(`/${item.link}`);
                            } else {
                              // console.log("closed!", myId);
                              // console.log(openSubMenus);
                              setOpenSubMenus([myId, ...openSubMenus]);
                            }
                          }}
                        >
                          {item.name}
                        </a>
                        {openSubMenus.indexOf(item.link) > -1 ? (
                          <ul>
                            {item.subItems.map((subItem, index3) => (
                              <li key={index3}>
                                <a
                                  className={`${
                                    subItem.emphasize ? "emphasize" : ""
                                  } ${
                                    myPathname === subItem.link
                                      ? "disabled"
                                      : ""
                                  }`}
                                  href={`/${subItem.link}`}
                                  disabled={myPathname === subItem.link}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onItemSelected(subItem.link);
                                    if (
                                      item.link === "programme" &&
                                      typeof window !== "undefined"
                                    ) {
                                      localStorage.removeItem("theme");
                                    }
                                  }}
                                >
                                  {subItem.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </li>
                    ) : (
                      <li key={index2}>
                        <a
                          className={`${item.emphasize ? "emphasize" : ""} ${
                            myPathname === item.link ? "disabled" : ""
                          }`}
                          href={`/${item.link}`}
                          disabled={myPathname === item.link}
                          onClick={(e) => {
                            e.preventDefault();
                            onItemSelected(item.link);
                            if (
                              item.link === "programme" &&
                              typeof window !== "undefined"
                            ) {
                              localStorage.removeItem("theme");
                            }
                          }}
                        >
                          {item.name}
                        </a>
                      </li>
                    )
                  )
                : null}
            </ul>
          ))}
          {realUseStore ? (
            <ul>
              <li>
                <a
                  href="/tote"
                  className={`emphasize ${
                    myPathname === "tote" ? "disabled" : ""
                  }`}
                  disabled={myPathname === "tote"}
                  onClick={(e) => {
                    e.preventDefault();
                    onItemSelected(`tote`);
                  }}
                >
                  Tote
                </a>
              </li>
            </ul>
          ) : null}
        </div>
      ) : null}
      <nav aria-label={"Alserkal on social media"}>
        <MiscSocialSmall useGlobalLink color="green" />
      </nav>
    </SlidingMenuWrapper>
  );
};

export default Menu;

Menu.propTypes = {
  /**
	This expects an array of menu groupings. 
	Each menu grouping is also an array, consisting of entries.
	Each entry consists of a `name`, an `id`, the boolean `emphasize` and possibly `subItems`.
	`subItems` are a list of entries (though they cannot contain their own `subEntries`).
	*/
  menuItems: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        emphasize: PropTypes.bool,
        subItems: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.string,
            emphasize: PropTypes.bool,
          })
        ),
      })
    )
  ),
  /**
	This function is fired if the menu is closed. Maybe this works differently?
	*/
  onCloseMenu: PropTypes.func,
  /**
	This function returns what's been clicked.
	*/
  onItemSelected: PropTypes.func,
};
