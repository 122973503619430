import styled from "styled-components";

export const ButtonContainer = styled.div`
  /* width: 125px; */
  // cursor: pointer;
  height: 43px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: all 0.3s;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  } */
  @media (max-width: 768px) {
    height: 36px;
    width: 87.21px;
  }
`;

export const Background = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const Text = styled.p`
  width: 99px;
  height: 43px;
  line-height: 43px;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.01em;
  text-align: center;
  vertical-align: middle;
  color: var(--btn-text);
  background-color: var(--btn-bg);
  @media (max-width: 768px) {
    height: 36px;
    width: 63.95px;
    font-size: 10px;
    line-height: 36px;
  }
`;
