import React from "react";
import logo from "../../assets/images/qaf_newlogo.png";
import { Logo } from "./elements";
// import PropTypes from "prop-types";

const LogoGif = () => (
  <>
    <div style={{height: "26px", backgroundColor: "#000"}}></div>
    <Logo src={logo} alt="Alserkal Logo" />
  </>
);

export default LogoGif;

// Logo.propTypes = {
//   color: PropTypes.string,
//   noText: PropTypes.bool,
// };
