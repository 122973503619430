import React from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";
import { HeaderWrapper, HeaderDiv } from "./elements";
import Search from "./../icons/Search";
import MenuOpen from "./../icons/MenuOpen";
import LogoGif from "../LogoGif";
import TicketButton from "../TicketButton";
import { navigate } from "gatsby";

const Header = ({
  loggedIn,
  openStore,
  openUser,
  openSearch,
  openMenu,
  hideUser,
  hideSearch,
  darkMode,
  skipHeader,
  headerColor,
  headerBlack,
  ticketButtonText,
  ticketButtonLink,
}) => {
  const [menuColor, setMenuColor] = React.useState(headerColor ? headerColor : darkMode ? "#eee" : "#111");

  return (
    <HeaderWrapper skipHeader={skipHeader} headerBlack={headerBlack}>
      <HeaderDiv className="fixed">
        <Link to={"/"}>
          <LogoGif color={menuColor} alt="Alserkal" />
        </Link>
        <ul>
          <li>
            <a id="test" href={ticketButtonLink} target="_blank">
                <TicketButton ticketButtonText={ticketButtonText} />
            </a>
          </li>
          {/* <li>
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                openSearch(true);
              }}
            >
              <Search alt="Search" color={menuColor} />
            </a>
          </li> */}
          <li>
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/search`);
              }}
              aria-label={"Seach"}
            >
              <Search hoverColor={"var(--primary)"} />
            </a>
          </li>
          <li>
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                openMenu(true);
              }}
            >
              <MenuOpen color={menuColor} alt="Menu" />
            </a>
          </li>
        </ul>
      </HeaderDiv>
    </HeaderWrapper>
  );
};

export default Header;

Header.propTypes = {
  /**
	Assuming that this is coming from somewhere else, though it could be set here?
	*/
  loggedIn: PropTypes.bool,
  /**
	These functions are fired if the button is clicked.
	*/
  openUser: PropTypes.func,
  /**
	These functions are fired if the button is clicked.
	*/
  openStore: PropTypes.func,
  /**
	These functions are fired if the button is clicked.
	*/
  openMenu: PropTypes.func,
  /**
	These functions are fired if the button is clicked.
	*/
  openSearch: PropTypes.func,
  hideStore: PropTypes.bool,
  hideUser: PropTypes.bool,
  hideSearch: PropTypes.bool,
  darkMode: PropTypes.bool,
  ticketButtonText: PropTypes.string,
  ticketButtonLink: PropTypes.string,
};
