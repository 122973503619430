import React, { useEffect } from "react";
import { navigate, useStaticQuery, graphql } from "gatsby";
import GlobalContext from "./../GlobalContext";
import Helmet from "react-helmet";
import Header from "./../Header";
import MainWrapper from "./../MainWrapper";
import Footer from "./../Footer";
import Menu from "./../Menu";
import { MenuWrapper, Wrapper } from "./elements";
import { Location } from "@reach/router";
import { helmetJsonLdProp } from "react-schemaorg";

const Layout = ({
  children,
  footerColor,
  backgroundColor,
  headerColor,
  aboveFooter,
  fullWidth,
  headerBlack,
  skipHeader,
  isFixed,
  title,
  seoTitle,
  SEODescription,
  SEOImageURL,
  SEOIsArticle,
}) => {
  const [menuShown, setMenuShown] = React.useState(false);

  const globalQueryBase = useStaticQuery(graphql`
    {
      craftGlobalSetInterface(handle: { eq: "siteQAFSettings" }) {
        ... on Craft_siteQAFSettings_GlobalSet {
          primaryColor
          textColor
          backgroundColor
          buttonBackground
          buttonTextColor
          id
          pageQAFTitleSeo
          pageQAFDesciptionSeo
          twitterLink
          remoteId
          linkedinLink
          facebookLink
          instagramLink
          ticketButtonText
          ticketButtonLink
          newsletterTitle
          newsletterDescription
          cursorIconNormal {
            ... on Craft_qafAssets_Asset {
              localFile {
                id
                publicURL
              }
            }
            ... on Craft_communityAssets_Asset {
              id
              localFile {
                publicURL
              }
            }
          }
          cursorIconHover {
            ... on Craft_qafAssets_Asset {
              localFile {
                id
                publicURL
              }
            }
            ... on Craft_communityAssets_Asset {
              id
              localFile {
                publicURL
              }
            }
          }
          newsletterImage {
            ... on Craft_qafAssets_Asset {
              id
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            ... on Craft_communityAssets_Asset {
              id
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          newsletterLink
          menuContent {
            ... on Craft_menuContent_menuSection_BlockType {
              menuSectionSubitems {
                link
                parentLink
                name
              }
              menuItem {
                link
                name
              }
            }
          }
          footerContent {
            ... on Craft_footerContent_footerEntry_BlockType {
              id
              footerItemName
              footerItemLink
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
          useStaging
        }
      }
      file(relativePath: { eq: "logo-alserkal-black.png" }) {
        publicURL
      }
    }
  `);

  const now = new Date();
  const globalQuery = globalQueryBase.craftGlobalSetInterface;
  globalQuery.siteURL = globalQueryBase.site.siteMetadata.siteUrl;
  globalQuery.useStaging = globalQueryBase.site.siteMetadata.useStaging === "true";
  const fallbackLogo = "https://alserkal.online/" + globalQueryBase.file.publicURL;
  // console.log("rendering!", menuShown);
  const fallbackTitle = globalQuery.pageQAFTitleSeo || "Quoz Arts Fest";
  const fallbackDescription =
    globalQuery.pageQAFDesciptionSeo ||
    "Quoz Arts Fests - Experience the vibrant Quoz Arts Fest 2024! Explore a fusion of creativity, performances, and art installations. Join us for an unforgettable celebration of artistic expression and cultural diversity.";
  const ticketButtonText = globalQuery.ticketButtonText || "TICKETS";
  const ticketButtonLink = globalQuery.ticketButtonLink || "https://alserkal.online";
  const newsLetterImage = globalQuery.newsletterImage?.length ? globalQuery.newsletterImage[0] : null;
  const newsletterTitle = globalQuery.newsletterTitle || "Join our newsletter";
  const newsletterDescription = globalQuery.newsletterDescription || "Be in the know on everything Alserkal";
  const newsletterLink =
    globalQuery.newsletterLink || "https://alserkalavenue.us8.list-manage.com/subscribe?u=89ea5b35584c53ada3b153c0f&id=db108ae6b9";

  useEffect(() => {
    document.documentElement.style.setProperty("--primary", globalQuery?.primaryColor || "#F04653");
    document.documentElement.style.setProperty("--text-color", globalQuery?.textColor || "#FFF");
    document.documentElement.style.setProperty("--bg-color", globalQuery?.backgroundColor || "#101820");
    document.documentElement.style.setProperty("--btn-bg", globalQuery?.buttonBackground || "#F04653");
    document.documentElement.style.setProperty("--btn-text", globalQuery?.buttonTextColor || "#101820");
  }, [
    globalQuery?.primaryColor,
    globalQuery?.textColor,
    globalQuery?.backgroundColor,
    globalQuery?.buttonBackground,
    globalQuery?.buttonBackground,
    globalQuery?.buttonTextColor,
  ]);

  useEffect(() => {
    document.documentElement.style.setProperty("--cursor-normal", `url(${globalQuery?.cursorIconNormal?.[0]?.localFile?.publicURL})`);
    document.documentElement.style.setProperty("--cursor-hover", `url(${globalQuery?.cursorIconHover?.[0]?.localFile?.publicURL})`);
  }, [globalQuery?.cursorIconNormal, globalQuery?.cursorIconHover]);

  return (
    <Location>
      {({ location }) => {
        const myPath = encodeURI(globalQuery.siteURL + location.pathname);
        return (
          <GlobalContext.Provider value={{ ...globalQuery, useStore: false }}>
            {globalQuery.useStaging ? null : (
              <Helmet
                htmlAttributes={{
                  lang: "en",
                }}
                script={[
                  helmetJsonLdProp({
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    name: "Quoz Arts Fest",
                    url: "https://quozartsfest.alserkal.online/",
                  }),
                  helmetJsonLdProp({
                    "@context": "https://schema.org",
                    "@type": "Event",
                    name: "Quoz Arts Fest",
                    startDate: "2024-01-27T00:00",
                    eventStatus: "https://schema.org/EventScheduled",
                    description:
                      "Returning in January, Quoz Arts Fest promises a packed weekend of electrifying live music performances, immersive art installations, delicious culinary offerings, and enriching workshops.",
                    endDate: "2024-01-28T23:59",
                    eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                    location: {
                      "@type": "Place",
                      name: "Alserkal Avenue",
                      address: {
                        "@type": "PostalAddress",
                        streetAddress: "17th Street",
                        addressLocality: " Al Quoz 1",
                        postalCode: "",
                        addressCountry: "AE",
                        addressRegion: "Dubai",
                      },
                    },
                  }),
                ]}
              >
                <title>{`${title || fallbackTitle} - The region's most loved annual arts and culture festival`}</title>
                <meta name="description" content={SEODescription || fallbackDescription} />
                <meta name="robots" content="index, follow" />
                <meta itemprop="name" content={title ? `Quoz Arts Fest: ${title}` : fallbackTitle} />
                <meta itemprop="description" content={SEODescription || fallbackDescription} />
                <meta itemprop="image" content={SEOImageURL || fallbackLogo} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@AlserkalAvenue" />
                <meta name="twitter:creator" content="@AlserkalAvenue" />
                <meta name="twitter:title" content={title ? `Quoz Arts Fest: ${title}` : fallbackTitle} />
                <meta name="twitter:description" content={SEODescription || fallbackDescription} />
                <meta name="twitter:image" content={SEOImageURL || fallbackLogo} />
                <meta property="og:title" content={title ? `Quoz Arts Fest: ${title}` : fallbackTitle} />
                <meta property="og:image" content={SEOImageURL || fallbackLogo} />
                <meta property="og:image:width" content={1200} />
                <meta property="og:image:height" content={600} />
                <meta property="og:type" content={SEOIsArticle ? "article" : "website"} />
                <meta property="og:description" content={SEODescription || fallbackDescription} />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:url" content={myPath} />
                <meta property="og:site_name" content="Quoz Arts Fest"></meta>
                <meta name="facebook-domain-verification" content="yftph99ln6t2q5v3om8t5jcd9id5dx" />
                <meta
                  name="keywords"
                  content="quoz arts fest, quozartsfest, alserkal avenue, dubai festival, festival, live music performances, arts, delicious culinary, workshops, kids' activities."
                />
                <link rel="canonical" href={myPath} />
              </Helmet>
            )}
            <MenuWrapper>
              <Wrapper backgroundColor={backgroundColor}>
                <Header
                  skipHeader={skipHeader}
                  darkMode={true}
                  headerColor={headerColor}
                  headerBlack={headerBlack}
                  openUser={(e) => {
                    console.log("open user");
                  }}
                  openSearch={(e) => {
                    navigate(`/search`);
                  }}
                  openStore={(e) => {
                    // console.log("open store");
                    navigate(`/tote`);
                  }}
                  openMenu={(e) => {
                    // console.log("menuShown:", menuShown);
                    setMenuShown(true);
                  }}
                  hideSearch={false}
                  ticketButtonText={ticketButtonText}
                  ticketButtonLink={ticketButtonLink}
                />
                <MainWrapper backgroundColor={backgroundColor} fullWidth isFixed={isFixed}>
                  <h1 className="d-none">{`Quoz Arts Fest ${now.getFullYear()} | ${title}`}</h1>
                  {children}
                </MainWrapper>
                {aboveFooter}
                <Footer
                  color={footerColor || "var(--primary)"}
                  // backgroundColorFooter={footerColor || "var(--primary)"}
                  reportLink={(e) => {
                    // console.log("Footer selected: ", e);
                    navigate(`/${e}`);
                  }}
                  newsletterTitle={newsletterTitle}
                  newsletterDescription={newsletterDescription}
                  newsLetterImage={newsLetterImage}
                  newsletterLink={newsletterLink}
                />
              </Wrapper>
              <nav className={menuShown ? "shown" : "hidden"}>
                <Menu
                  onCloseMenu={() => {
                    setMenuShown(false);
                  }}
                  onItemSelected={(e) => {
                    console.log("Menu selected: ", e);
                    navigate(`/${e}`);
                  }}
                />
              </nav>
            </MenuWrapper>
          </GlobalContext.Provider>
        );
      }}
    </Location>
  );
};

export default Layout;

Layout.defaultProps = {
  skipHeader: false,
};
