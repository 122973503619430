import styled from "styled-components";

export const Logo = styled.img`
  width: 101px;
  height: auto;
  @media (max-width: 768px) {
    width: 40px;
    height: 46px;
    padding-top: 10px;
  }
`;
